import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useApi } from "@providers/api";
import { useUser } from "@providers/user";
import SelectAccount from "./components/SelectAccount";
import BreadCrumb from "@shared/BreadCrumb";

export default function User() {
  const api = useApi();
  const { offices } = useUser();
  const [user, setUser] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [availableOffices, setAvailableOffices] = useState([]);
  const [selectedOffices, setSelectedOffices] = useState([]);
  const { id } = useParams();

  const superUser = useMemo(
    () => selectedRoles.includes("super"),
    [selectedRoles]
  );

  const salesRepUser = useMemo(
    () => selectedRoles.includes("sales_rep"),
    [selectedRoles]
  );

  const accountingUser = useMemo(
    () => selectedRoles.includes("accounting"),
    [selectedRoles]
  );

  const missingOffices = !user?.office_users?.length;

  const load = () =>
    api.get(`/users/${id}?include=office_users`).then((user) => {
      setUser(user);
      setSelectedRoles(user.user_roles.map((ur) => ur.role));
      setSelectedAccounts(user.accounts.map((account) => account.id));
      setSelectedOffices(user.office_users.map((ou) => ou.office.id));
    });

  useEffect(() => {
    load();
    api.get("/accounts").then((response) => {
      setAccounts(response);
    });
  }, [id]);

  useEffect(() => {
    if (!user || !accounts || !offices || !selectedAccounts) {
      return;
    }

    let accountId = user.accounts[0]?.id;
    if (!accountId) {
      setAvailableOffices([]);
      return;
    }

    let account = accounts.find((account) => account.id === accountId);
    if (!account) {
      setAvailableOffices([]);
      return;
    }

    setAvailableOffices(
      account.offices?.filter((office) => {
        return (
          office.enabled &&
          !user.office_users.find(
            (office_user) => office_user.office.id === office.id
          )
        );
      }) || []
    );
  }, [user, accounts, selectedAccounts, offices]);

  if (!user || !accounts.length) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    data.roles = selectedRoles;

    // handle the multiselect
    if (superUser) {
      data.account_ids = [];
      data.office_ids = [];
      data.roles = ["super"];
    } else {
      if (!selectedAccounts.length) {
        return alert("Please select an account");
      }

      if (salesRepUser) {
        data.account_ids = selectedAccounts;
        data.office_ids = [];
      } else {
        data.account_ids = [selectedAccounts[0]];
        data.office_ids = selectedOffices;
      }
    }

    data.email = data.email?.toLowerCase();

    api.put(`/users/${id}`, { user: data }).then(async (response) => {
      if (response?.id) {
        await load();
        alert("User updated successfully", "green");
      } else {
        alert("An error occurred");
      }
    });
  };

  const addOffice = () => {
    const office_id = document.getElementById("office").value;
    api
      .post(`/office_users`, { office_user: { office_id, user_id: user.id } })
      .then((response) => {
        if (response?.id) {
          document.getElementById("office").value = "";
          load();
        } else {
          alert("An error occurred");
        }
      });
  };

  const handleDeleteOfficeUser = (office_user_id) => {
    if (window.confirm("Are you sure you want to remove this office?")) {
      api.delete(`/office_users/${office_user_id}`).then(load);
    }
  };

  const handleRoleChange = (role, checked) => {
    if (checked) {
      setSelectedRoles([...selectedRoles, role]);
    } else {
      setSelectedRoles(selectedRoles.filter((r) => r !== role));
    }
  };

  return (
    <div className="container">
      <BreadCrumb
        items={[["Admin", "/admin"], ["Users", "/admin/users"], user.name]}
      />
      {missingOffices && !superUser && !salesRepUser && !accountingUser && (
        <div style={{ color: "red", padding: 10 }}>
          This user is missing offices. Please assign them to an office to
          enable them to log in
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" defaultValue={user.name} />
          </div>
          <div className="column">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              style={{ textTransform: "lowercase" }}
              defaultValue={user.email}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Phone</label>
            <input
              type="text"
              name="phone"
              id="phone"
              defaultValue={user.phone}
            />
          </div>
          <div className="column">
            <label htmlFor="">Phone Extension</label>
            <input type="text" name="ext" id="ext" defaultValue={user.ext} />
          </div>
          <div className="column">
            <label htmlFor="fax">Fax</label>
            <input type="text" name="fax" id="fax" defaultValue={user.fax} />
          </div>
        </div>
        <div className="row">
          <div className="column column-33">
            <label htmlFor="role">Role</label>
            {superUser ? null : (
              <>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedRoles.includes("admin")}
                    onChange={(e) =>
                      handleRoleChange("admin", e.target.checked)
                    }
                  />
                  &nbsp;&nbsp;Admin
                </label>

                <label>
                  <input
                    type="checkbox"
                    checked={selectedRoles.includes("doctor")}
                    onChange={(e) =>
                      handleRoleChange("doctor", e.target.checked)
                    }
                  />
                  &nbsp;&nbsp;Doctor
                </label>

                <label>
                  <input
                    type="checkbox"
                    checked={selectedRoles.includes("accounting")}
                    onChange={(e) =>
                      handleRoleChange("accounting", e.target.checked)
                    }
                  />
                  &nbsp;&nbsp;Accounting
                </label>

                <label>
                  <input
                    type="checkbox"
                    checked={selectedRoles.includes("sales_rep")}
                    onChange={(e) =>
                      handleRoleChange("sales_rep", e.target.checked)
                    }
                  />
                  &nbsp;&nbsp;Sales Rep
                </label>
              </>
            )}

            <label>
              <input
                type="checkbox"
                checked={selectedRoles.includes("super")}
                onChange={(e) => handleRoleChange("super", e.target.checked)}
              />
              &nbsp;&nbsp;⚠️ Super User
            </label>
          </div>

          <SelectAccount
            accounts={accounts}
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            superUser={superUser}
            salesRepUser={salesRepUser}
            selectedOffices={selectedOffices}
            setSelectedOffices={setSelectedOffices}
          />
        </div>
        <button type="submit">Update</button>
      </form>
      <hr />
      <h4>Update Password</h4>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.target);
          const data = Object.fromEntries(formData);
          api
            .put(`/users/${id}/update-password`, { user: data })
            .then((response) => {
              if (response?.id) {
                alert("Password updated successfully", "green");
              } else {
                if (response?.password_confirmation) {
                  alert("Password and confirmation do not match");
                } else {
                  alert("An error occurred");
                }
              }
            });
        }}
      >
        <div>
          <label htmlFor="password">Password</label>
          <input required type="password" name="password" id="password" />
        </div>
        <div>
          <label htmlFor="password">Confirm Password</label>
          <input
            required
            type="password"
            name="password_confirmation"
            id="password_confirmation"
          />
        </div>
        <button type="submit">Update Password</button>
      </form>
      <hr />
      {superUser || accountingUser || salesRepUser ? null : (
        <>
          <h3>Assigned Offices</h3>
          {user?.office_users?.map((office_user) => {
            return (
              <div key={office_user.id}>
                <a
                  onClick={() => handleDeleteOfficeUser(office_user.id)}
                  style={{
                    display: "inline-block",
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    backgroundColor: "firebrick",
                    color: "white",
                    textAlign: "center",
                    lineHeight: "24px",
                    textDecoration: "none",
                    marginRight: "10px",
                    fontWeight: "bold",
                  }}
                >
                  &times;
                </a>
                <Link to={`/admin/offices/${office_user.office.id}`}>
                  {office_user.office.name}
                </Link>
              </div>
            );
          })}
          <br />

          <div className="row">
            <div className="column">
              <select name="office" id="office">
                <option value="">Add another office</option>
                {availableOffices.map((office) => {
                  return <option value={office.id}>{office.name}</option>;
                })}
              </select>
            </div>
            <div className="column">
              <button onClick={addOffice}>Add Office</button>
            </div>
          </div>
        </>
      )}
      <hr />
      {user.active ? (
        <DisableUser user={user} load={load} />
      ) : (
        <EnableUser user={user} load={load} />
      )}
    </div>
  );
}

const EnableUser = ({ user, load }) => {
  const api = useApi();

  return (
    <>
      <h3>Enable User</h3>
      <p>
        Enabling a user will allow them to log in. They will be able to place
        orders and access their account
      </p>
      <button
        onClick={() => {
          if (window.confirm("Are you sure you want to enable this user?")) {
            api
              .put(`/users/${user.id}`, { user: { active: true } })
              .then((response) => {
                if (response?.id) {
                  load();
                } else {
                  alert("An error occurred");
                }
              });
          }
        }}
      >
        Enable User
      </button>
    </>
  );
};

const DisableUser = ({ user, load }) => {
  const api = useApi();

  return (
    <>
      <h3>Disable User</h3>
      <p>
        Disabling a user will prevent them from logging in. Their orders will
        still be accessible by other people in their office. Furthermore, this
        user will still be reflected as an the ordering user on any orders they
        previously placed
      </p>
      <button
        onClick={() => {
          if (window.confirm("Are you sure you want to disable this user?")) {
            api
              .put(`/users/${user.id}`, { user: { active: false } })
              .then((response) => {
                if (response?.id) {
                  load();
                } else {
                  alert("An error occurred");
                }
              });
          }
        }}
      >
        Disable User
      </button>
    </>
  );
};
