import React, { useState, useEffect, useMemo } from "react";
import BreadCrumb from "../../../shared/BreadCrumb";
import { useApi } from "@providers/api";
import { useUser } from "@providers/user";
import SelectAccount from "./components/SelectAccount";

export default function CreateUser() {
  const api = useApi();
  const { offices } = useUser();
  const [createMany, setCreateMany] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedOffices, setSelectedOffices] = useState([]);

  const superUser = useMemo(
    () => selectedRoles.includes("super"),
    [selectedRoles]
  );

  const salesRepUser = useMemo(
    () => selectedRoles.includes("sales_rep"),
    [selectedRoles]
  );

  useEffect(() => {
    api.get("/accounts").then((response) => {
      setAccounts(response);
    });
  }, []);

  const createUser = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const user = {
      name: formData.get("name"),
      email: formData.get("email").toLowerCase(),
      roles: selectedRoles,
      phone: formData.get("phone"),
      ext: formData.get("ext"),
    };

    if (superUser) {
      user.account_ids = [];
      user.office_ids = [];
      user.roles = ["super"];
    } else {
      if (!selectedAccounts.length) {
        return alert("Please select an account");
      }

      if (salesRepUser) {
        user.account_ids = selectedAccounts;
        user.office_ids = [];
      } else {
        user.account_ids = [selectedAccounts[0]];
        user.office_ids = selectedOffices;
      }
    }

    api.post("/users", { user }).then((response) => {
      if (response.error) {
        alert(response.error);
      } else if (!response.id && response.length > 0) {
        alert(response.join("\n"));
      } else {
        form.reset();

        if (!createMany) {
          window.location = "/admin/users/" + response.id;
        }
      }
    });
  };

  const handleRoleChange = (role, checked) => {
    if (checked) {
      setSelectedRoles([...selectedRoles, role]);
    } else {
      setSelectedRoles(selectedRoles.filter((r) => r !== role));
    }
  };

  return (
    <div className="container">
      <BreadCrumb
        items={[["Admin", "/admin"], ["Users", "/admin/users"], "Create User"]}
      />
      <form onSubmit={createUser}>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Name</label>
            <input required type="text" name="name" id="name" />
          </div>
          <div className="column">
            <label htmlFor="email">Email</label>
            <input
              required
              type="email"
              name="email"
              id="email"
              style={{ textTransform: "lowercase" }}
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <label htmlFor="phone">Phone</label>
            <input type="tel" name="phone" id="phone" />
          </div>
          <div className="column">
            <label htmlFor="ext">Extension</label>
            <input type="text" name="ext" id="ext" />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <label htmlFor="role">Roles</label>
            {superUser ? null : (
              <>
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleRoleChange("admin", e.target.checked)
                    }
                  />
                  &nbsp;&nbsp;Admin
                </label>

                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleRoleChange("doctor", e.target.checked)
                    }
                  />
                  &nbsp;&nbsp;Doctor
                </label>

                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleRoleChange("accounting", e.target.checked)
                    }
                  />
                  &nbsp;&nbsp;Accounting
                </label>

                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleRoleChange("sales_rep", e.target.checked)
                    }
                  />
                  &nbsp;&nbsp;Sales Rep
                </label>
              </>
            )}

            <label>
              <input
                type="checkbox"
                onChange={(e) => handleRoleChange("super", e.target.checked)}
              />
              &nbsp;&nbsp;⚠️ Super User
            </label>
          </div>

          <SelectAccount
            accounts={accounts}
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            superUser={superUser}
            salesRepUser={salesRepUser}
            offices={offices}
            setSelectedOffices={setSelectedOffices}
          />
        </div>

        <div className="row">
          <div className="column">
            <button type="submit">Create User</button>
          </div>
          <div className="column text-right">
            <label>
              <input
                type="checkbox"
                checked={createMany}
                onChange={() => setCreateMany(!createMany)}
              />
              &nbsp;&nbsp;Check this box if you intend to create many users
            </label>
          </div>
        </div>
      </form>
    </div>
  );
}

const SelectOffice = ({ selectedRole, offices, accounts }) => {
  if (selectedRole === "super") {
    return null;
  } else {
    return (
      <>
        <label htmlFor="account_id">Account</label>
        <select required name="account_id" id="account_id">
          <option value="">Select Account</option>
          {accounts?.map((account) => (
            <option key={account.id} value={account.id}>
              {account.name}
            </option>
          ))}
        </select>
      </>
    );
  }
};
